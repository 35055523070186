<template>
    <div v-if="isModalError === true" class="absolute top-0 right-0 left-0 bottom-0 z-50 px-4 min-h-screen md:flex md:items-center md:justify-center">
        <div class="bg-black opacity-25 w-full h-full absolute z-10 inset-0" @click="dismisError()"></div>
        <div class="bg-white rounded-lg md:max-w-md md:mx-auto p-4 fixed inset-x-0 bottom-0 z-50 mb-4 mx-4 md:relative">
            <div class="flex flex-col align-middle items-center">
                <img src="@/assets/images/fail.svg" class="w-32 mb-4"/>
                <p>Maaf, terjadi kesalahan jaringan. <br/>Mohon coba beberapa saat lagi...</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ErrorModal',
    computed: {
        isModalError () {
            return this.$store.getters.isModalError
        }
    },
    methods : {
        dismisError () {
            this.$store.dispatch("actionOnError", false)	
        }
    }
}
</script>
