let getters = {
    userLogin: state => {
        return state.userLogin
    },
    getNonReadNotification: state => {
        return state.nonReadNotification
    },
    isModalError: state => {
        return state.isModalError
    },
    getNotification (state) {
        return state.notification
    },
    getKonten (state) {
        return state.konten
    },
}

export default  getters