<template>

    <transition name="fade" v-if="notificationData != null" class="">
        <div @click="hideNotif" class="animate__animated animate__slideInDown px-3 py-2 md:rounded-lg shadow-md md:max-w-xs w-full fixed md:right-5 md:top-5 top-0 right-0 " style="z-index:9999999"
            :class="
                notificationData.type == 'success' ? 'bg-green-500' : (notificationData.type == 'error' ? 'bg-red-500' : 'bg-yellow-400')"
        >
            <div class="flex items-center cursor-pointer text-white">
                <div class="flex flex-shrink-0 items-end">
                    <img class="h-6 w-6 rounded-full" src="../assets/images/012-checkmark-2.svg" v-if="notificationData.type == 'success'">
                    <img class="h-6 w-6 rounded-full" src="../assets/images/091-warning.svg" v-if="notificationData.type == 'warning'">
                    <img class="h-6 w-6 rounded-full" src="../assets/images/019-close.svg" v-if="notificationData.type == 'error'">
                </div>
                <div class="ml-3">
                    <p class="text-sm">{{ notificationData.message }}</p>
                </div>
            </div>
        </div>
    </transition>

</template>

<script>
export default {
    name: 'toastNotification',
    computed: {
        notificationData () {
            return this.$store.getters.getNotification
        }
    },
    methods: {
        hideNotif() {
            this.$store.dispatch('actionShowNotification', null)
        },
    }
}
</script>

