const Home = () => import( /* webpackChunkName:"Home" */ './pages/Home')

const Login             = () => import( /* webpackChunkName:"Login" */ './pages/Login')
const ResetPassword     = () => import( /* webpackChunkName:"ResetPassword" */ './pages/ResetPassword')
const Register          = () => import( /* webpackChunkName:"Register" */ './pages/Register')
const DashboardKategori = () => import( /* webpackChunkName:"DashboardKategori" */ './pages/kategori/DashboardKategori')

// kategori -> modul
const ModulByType           = () => import( /* webpackChunkName:"ModulByType" */ './pages/kategori/ModulByType')
const DetailModul           = () => import( /* webpackChunkName:"DetailModul" */ './pages/kategori/DetailModul')
const ModulUser             = () => import( /* webpackChunkName:"ModulUser" */ './pages/kategori/ModulUser')
const MateriSubKategori     = () => import( /* webpackChunkName:"MateriSubKategori" */ './pages/kategori/MateriSubKategori')
const MateriSubKategoriList = () => import( /* webpackChunkName:"MateriSubKategoriList" */ './pages/kategori/MateriSubKategoriList')
const ViewMateriKonten      = () => import( /* webpackChunkName:"ViewMateriKonten" */ './pages/materi/ViewMateriKonten')
const PembahasanKuisMateri      = () => import( /* webpackChunkName:"PembahasanKuisMateri" */ './pages/materi/PembahasanKuisMateri')
const JawabKuisMateri       = () => import( /* webpackChunkName:"JawabKuisMateri" */ './pages/materi/JawabKuisMateri')

// kuis 
const JawabKuis        = () => import( /* webpackChunkName:"JawabKuis" */ './pages/kuis/JawabKuis')
const QuizResult       = () => import( /* webpackChunkName:"QuizResult" */ './pages/kuis/QuizResult')
const QuizResultDetail = () => import( /* webpackChunkName:"QuizResultDetail" */ './pages/kuis/QuizResultDetail')
const QuizReview       = () => import( /* webpackChunkName:"QuizReview" */ './pages/kuis/QuizReview')
const QuizReviewDetail = () => import( /* webpackChunkName:"QuizReviewDetail" */ './pages/kuis/QuizReviewDetail')

const Peringkat       = () => import( /* webpackChunkName:"Peringkat" */ './pages/kuis/Peringkat')
const PeringkatDetail = () => import( /* webpackChunkName:"PeringkatDetail" */ './pages/kuis/PeringkatDetail')

// user
const ProfilMain              = () => import( /* webpackChunkName:"ProfilMain" */ './pages/profil/ProfilMain')
const ProfilIndex             = () => import( /* webpackChunkName:"ProfilIndex" */ './pages/profil/ProfilIndex')
const ProfilPassword          = () => import( /* webpackChunkName:"ProfilPassword" */ './pages/profil/ProfilPassword')
const ProfilUbah              = () => import( /* webpackChunkName:"ProfilUbah" */ './pages/profil/ProfilUbah')
const Pemberitahuan           = () => import( /* webpackChunkName:"Pemberitahuan" */ './pages/Pemberitahuan')
const PemberitahuanRedirecter = () => import( /* webpackChunkName:"PemberitahuanRedirecter" */ './pages/PemberitahuanRedirecter')

const PromoIndex  = () => import( /* webpackChunkName:"PromoIndex" */ './pages/promo/PromoIndex')
const PromoDetail = () => import( /* webpackChunkName:"PromoDetail" */ './pages/promo/PromoDetail')

const Rapor       = () => import( /* webpackChunkName:"Rapor" */ './pages/kategori/Rapor')
const RaporPdf    = () => import( /* webpackChunkName:"RaporPdf" */ './pages/kategori/RaporPdf')
const RaporDetail = () => import( /* webpackChunkName:"RaporDetail" */ './pages/kategori/RaporDetail')

const TagihanIndex  = () => import( /* webpackChunkName:"TagihanIndex" */ './pages/tagihan/TagihanIndex')
const TagihanDetail = () => import( /* webpackChunkName:"TagihanDetail" */ './pages/tagihan/TagihanDetail')
const ReportSoal    = () => import( /* webpackChunkName:"ReportSoal" */ './pages/ReportSoal')


import userLogin from './middleware/userLogin'

// explore paket
const ExplorePaket = () => import( /* webpackChunkName:"ExplorePaket" */ './pages/ExplorePaket')

const routes = [
    {
        path: '',
        name: 'appHome',
        meta: {
            layout: 'default',
        },
        component: Home
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            layout: 'clear'
        },
        component: Login
    },
    {
        path: '/reset-password',
        name: 'resetPassword',
        meta: {
            layout: 'clear'
        },
        component: ResetPassword
    },
    {
        path: '/register',
        name: 'register',
        meta: {
            layout: 'clear'
        },
        component: Register
    },

    {
        path: '/pemberitahuan',
        name: 'pemberitahuan',
        meta: {
            layout: 'default'
        },
        component: Pemberitahuan
    },
    {
        path: '/pemberitahuan/redirecter/:id_pemberitahuan',
        name: 'PemberitahuanRedirecter',
        meta: {
            layout: 'default'
        },
        component: PemberitahuanRedirecter
    },
    
    {
        path: '/tagihan',
        name: 'tagihanIndex',
        meta: {
            layout: 'default'
        },
        component: TagihanIndex
    },
    {
        path: '/tagihan/:id_paket_user',
        name: 'TagihanDetail',
        meta: {
            layout: 'default'
        },
        component: TagihanDetail
    },

    {
        path: '/promo',
        name: 'PromoIndex',
        meta: {
            layout: 'default',
        },
        component: PromoIndex
    },

    {
        path: '/promo/:id_promo',
        name: 'PromoDetail',
        meta: {
            layout: 'default',
        },
        component: PromoDetail
    },
    
    // profil page
    {
        path: '/profil',
        meta: {
            layout: 'default',
        },
        component: ProfilMain,
        children: [
            {
                path: 'home', component: ProfilIndex, name: 'ProfilIndex',
                meta: {
                    middleware: [
                        userLogin
                    ]
                },
            },
            {
                path: 'ubah', component: ProfilUbah, name: 'ProfilUbah',
                meta: {
                    middleware: [
                        userLogin
                    ]
                },
            },
            {
                path: 'password', component: ProfilPassword, name: 'ProfilPassword',
                meta: {
                    middleware: [
                        userLogin
                    ]
                },
            },
        ]
    },
     
    {
        path: '/:singkatan_kategori',
        name: 'dashboardKategori',
        meta: {
            layout: 'default'
        },
        component: DashboardKategori
    },

    // materi
    {
        path: '/:singkatan_kategori/materi',
        name: 'MateriSubKategori',
        meta: {
            layout: 'default'
        },
        component: MateriSubKategori
    },
    {
        path: '/:singkatan_kategori/materi/:singkatan_sub_kategori',
        name: 'MateriSubKategoriList',
        meta: {
            layout: 'default'
        },
        component: MateriSubKategoriList
    },

    {
        path: '/:singkatan_kategori/materi/:singkatan_sub_kategori/:id_materi/:id_materi_konten',
        name: 'ViewMateriKonten',
        meta: {
            layout: 'default'
        },
        component: ViewMateriKonten
    },

    {
        path: '/:singkatan_kategori/materi/:singkatan_sub_kategori/:id_materi/:id_materi_konten/:id_materi_user_skor',
        name: 'PembahasanKuisMateri',
        meta: {
            layout: 'default'
        },
        component: PembahasanKuisMateri
    },

    {
        path: '/:singkatan_kategori/materi/:singkatan_sub_kategori/:id_materi/:id_materi_konten/jawab-kuis/:token',
        name: 'JawabKuisMateri',
        meta: {
            layout: 'empty'
        },
        component: JawabKuisMateri
    },

    {
        path: '/:singkatan_kategori/rapor',
        name: 'Rapor',
        meta: {
            layout: 'default',
            middleware: [
                userLogin
            ]
        },
        component: Rapor
    },
    {
        path: '/:singkatan_kategori/rapor/pdf',
        name: 'RaporPdf',
        meta: {
            layout: 'default',
            middleware: [
                userLogin
            ]
        },
        component: RaporPdf
    },
    {
        path: '/:singkatan_kategori/rapor/:id_kuis',
        name: 'RaporDetail',
        meta: {
            layout: 'default',
            middleware: [
                userLogin
            ]
        },
        component: RaporDetail
    },

    {
        path: '/:singkatan_kategori/modul/saya',
        name: 'modulUser',
        meta: {
            layout: 'default',
            middleware: [
                userLogin
            ]
        },
        component: ModulUser
    },
    {
        path: '/:singkatan_kategori/modul/:tipe_modul',
        name: 'kategoriGetModulByType',
        meta: {
            layout: 'default'
        },
        component: ModulByType
    },
    {
        path: '/:singkatan_kategori/modul/:tipe_modul/:id_paket',
        name: 'detailModul',
        meta: {
            layout: 'default'
        },
        component: DetailModul
    },


    // kuis
    {
        path: '/kuis/jawab/:token',
        name: 'JawabKuis',
        meta: {
            layout: 'empty',
            middleware: [
                userLogin
            ]
        },
        component: JawabKuis
    },

    {
        path: '/:singkatan_kategori/kuis/hasil',
        name: 'QuizResult',
        meta: {
            layout: 'default',
            middleware: [
                userLogin
            ]
        },
        component: QuizResult
    },

    {
        path: '/:singkatan_kategori/kuis/hasil/:id_paket_kuis_skor',
        name: 'QuizResultDetail',
        meta: {
            layout: 'default',
            middleware: [
                userLogin
            ]
        },
        component: QuizResultDetail
    },

    {
        path: '/:singkatan_kategori/pembahasan',
        name: 'QuizReview',
        meta: {
            layout: 'default',
            middleware: [
                userLogin
            ]
        },
        component: QuizReview
    },

    {
        path: '/:singkatan_kategori/pembahasan/:id_kuis',
        name: 'QuizReviewDetail',
        meta: {
            layout: 'default',
            middleware: [
                userLogin
            ]
        },
        component: QuizReviewDetail
    },

    {
        path: '/:singkatan_kategori/peringkat',
        name: 'Peringkat',
        meta: {
            layout: 'default',
            middleware: [
                userLogin
            ]
        },
        component: Peringkat
    },

    {
        path: '/:singkatan_kategori/peringkat/:id_kuis',
        name: 'PeringkatDetail',
        meta: {
            layout: 'default',
            middleware: [
                userLogin
            ]
        },
        component: PeringkatDetail
    },

    {
        path: '/explore-paket/:tipe_modul',
        name: 'ExplorePaket',
        meta: {
            layout: 'default',
        },
        component: ExplorePaket
    },

    {
        path: '/report-soal/:singkatan_kategori/:id_kuis/:id_kuis_soal',
        name: 'ReportSoal',
        meta: {
            layout: 'default',
        },
        component: ReportSoal
    },

    

]

export default routes
