let mutations = {

    changeUserLoginData (state, payload) {
        state.userLogin = payload
    },

    changeIsModalError (state, payload) {
        state.isModalError = payload
    },

    changeNotification (state, payload) {
        state.notification = payload
    },

    changeNonReadNotif (state, payload) {
        state.nonReadNotification = payload
    },

    changeKonten (state, payload) {
        state.konten = payload
    },

}
export default mutations