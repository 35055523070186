import axios from 'axios'
// axios.defaults.baseURL = 'http://localhost:8000/api';
axios.defaults.baseURL = 'https://kartika.adisparta.xyz/api';

let actions = {
    
    actionLoginUser({ commit }, payload) {
        localStorage.removeItem('dashboardUser')
        localStorage.setItem('userLogin', JSON.stringify(payload))
        commit('changeUserLoginData', payload)
    },

    actionOnError({ commit }, payload) {
        commit('changeIsModalError', payload)
    },

    actionOnNonReadNotif({ commit }, payload) {
        commit('changeNonReadNotif', payload)
    },
    
    actionChangeKonten({ commit }, payload) {
        commit('changeKonten', payload)
    },

    // api data
    async postLogin({commit}, params) {
        let res = await axios.post('/user/login', params);
        return res;
    },

    async postResetPassword({commit}, params) {
        let res = await axios.post('/user/reset-password', params);
        return res;
    },

    async getUserData({commit, state}, params) {
        let res = await axios.get('/user/profil', {
            headers: {
                'Authorization': state.userLogin == null ? null : `Bearer ${state.userLogin.token}`
            }
        });
        return res;
    },

    async postRegister({commit}, params) {
        let res = await axios.post('/user/register', params);
        return res;
    },

    async gantiAvatar({commit, state}, params) {
        let res = await axios.post('/user/avatar', params.formData, {
            headers: {
                'Authorization': state.userLogin == null ? null : `Bearer ${state.userLogin.token}`
            }
        });
        return res;
    },

    async getPemberitahuan({commit, state}, params) {
        let res = await axios.get(`/user/pemberitahuan?page=${params.page}`, {
            headers: {
                'Authorization': state.userLogin == null ? null : `Bearer ${state.userLogin.token}`
            }
        });
        return res;
    },

    async readNotification({commit, state}, params) {
        let res = await axios.post(`/user/pemberitahuan/${params.id_pemberitahuan}`, {}, {
            headers: {
                'Authorization': state.userLogin == null ? null : `Bearer ${state.userLogin.token}`
            }
        });
        return res;
    },

    async postGantiProfil({commit, state}, params) {
        let res = await axios.post('/user/profil', params,
            {
                headers: {
                    'Authorization': state.userLogin == null ? null : `Bearer ${state.userLogin.token}`
                }
            }
        );
        return res;
    },

    async postGantiPassword({commit, state}, params) {
        let res = await axios.post('/user/password', params,
            {
                headers: {
                    'Authorization': state.userLogin == null ? null : `Bearer ${state.userLogin.token}`
                }
            }
        );
        return res;
    },

    async getKategori() {
        let res = await axios.get('/master/kategori');
        return res;
    },

    async getDashboardInfo({ commit, state}) {
        let dashboardUser = localStorage.getItem('dashboardUser');
        if (dashboardUser != null) {
            return JSON.parse(dashboardUser);
        }

        let res = await axios.get('/master/dashboard',
            {
                headers: {
                    'Authorization': state.userLogin == null ? null : `Bearer ${state.userLogin.token}`
                }
            }
        );
        localStorage.setItem('dashboardUser', JSON.stringify(res))
        return res;
    },

    async getNonReadNotifCount({ commit, state}) {
        let res = await axios.get('/user/pemberitahuan/count',
            {
                headers: {
                    'Authorization': state.userLogin == null ? null : `Bearer ${state.userLogin.token}`
                }
            }
        );
        return res;
    },

    async getBanner() {
        let bannerData = localStorage.getItem('bannerData');
        if (bannerData != null) {
            // return JSON.parse(bannerData);
        }
        let res = await axios.get('/master/banner');
        localStorage.setItem('bannerData', JSON.stringify(res))
        return res;
    },

    async getKategoriDetail({ commit, state }, singkatan) {
        let res = await axios.get('/master/kategori/' + singkatan,
            {
                headers: {
                    'Authorization': state.userLogin == null ? null : `Bearer ${state.userLogin.token}`
                }
            }
        );
        return res;
    },


    async getSubKategoriDetail({ commit, state }, singkatan) {
        let res = await axios.get('/master/sub_kategori/' + singkatan,
            {
                headers: {
                    'Authorization': state.userLogin == null ? null : `Bearer ${state.userLogin.token}`
                }
            }
        );
        return res;
    },


    // modul
    /**
     * Mengambil paket berdasarkan id_kategori dan status_akses
     * @param {*} param0 
     * @param {*} params 
     * status_akses : gratis, event, berbayar
     */
    async getModulByKategoryStatusBayar({ commit }, params) {
        let res = await axios.get(`paket/by-kategori-status/${params.id_kategori}/${params.status_akses}?page=${params.page}`);
        return res;
    },

    /**
     * Get paket berdasarkan status bayar
     * @param {*} param0 
     * @param {*} params 
     */
    async getModulByStatusBayar({ commit }, params) {
        let res = await axios.get(`paket/by-status-bayar/${params.status_akses}?page=${params.page}`);
        return res;
    },

    async getPaketJoinedByUser({ commit, state }, params) {
        let res = await axios.get(`paket/user-paket?page=${params.page}`, {
            headers: {
                'Authorization': state.userLogin == null ? null : `Bearer ${state.userLogin.token}`
            }
        });
        return res;
    },



    /**
     * Mengambil detail modul
     * @param {*} param0 
     * @param {*} id_modul 
     */
    async getModulDetail({ commit, state }, id_modul) {
        let res = await axios.get(`paket/${id_modul}`,
            {
                headers: {
                    'Authorization': state.userLogin == null ? null : `Bearer ${state.userLogin.token}`
                }
            }
        );
        return res;
    },

    /**
     * Join ke modul
     * @param {*} param0 
     * @param {*} params 
     */
    async joinToModul({ commit, state }, params) {
        let res = await axios.post(
            '/paket/' + params.id_paket + '/join',
            params.formData,
            {
                headers: {
                    'Authorization': state.userLogin == null ? null : `Bearer ${state.userLogin.token}`
                }
            }
        );
        return res;
    },

    /**
     * Konfirmasi pembayaran modul
     * @param {*} param0 
     * @param {*} params 
     */
    async konfirmasiBayarModul({ commit, state }, params) {
        let res = await axios.post(
            '/paket/' + params.id_paket + '/join/konfirmasi-pembayaran',
            params.formData,
            {
                headers: {
                    'Authorization': state.userLogin == null ? null : `Bearer ${state.userLogin.token}`
                }
            }
        );
        return res;
    },


    /**
     * Set toast notification
     * @param {*} param0 
     * @param {*} payload 
     */
    actionShowNotification({ commit }, payload) {
        commit('changeNotification', payload)
    },


    /**
     * Start kuis oleh user
     * @param {*} param0 
     * @param {*} params 
     */
    async kuisStart({ commit, state}, params) {
        let res = await axios.post(
            `/kuis/${params.id_paket}/${params.id_paket_user}/${params.id_kuis}/start`,
            params,
            {
                headers: {
                    'Authorization': state.userLogin == null ? null : `Bearer ${state.userLogin.token}`
                }
            }
        );
        return res;
    },


    /**
     * Get detail menjawab kuis berdasarkan token bycript
     * @param {*} param0 
     * @param {*} params 
     */
    async kuisSkorDetailByToken({ commit, state }, params) {
        let res = await axios.get(
            `/kuis/detail-by-token/${params.token}`,
            {
                headers: {
                    'Authorization': state.userLogin == null ? null : `Bearer ${state.userLogin.token}`
                }
            }
        );
        return res;
    },

    /**
     * Ambil pertanyaan per token
     * @param {*} param0 
     * @param {*} params 
     */
    async getPertanyaanPerTokenKuis({ commit, state }, params) {
        let res = await axios.get(
            `/kuis/pertanyaan/${params.token}?page=${params.page}`,
            {
                headers: {
                    'Authorization': state.userLogin == null ? null : `Bearer ${state.userLogin.token}`
                }
            }
        );
        return res;
    },

    async postJawaban({ commit, state }, params) {
        let res = await axios.post(
            `/kuis/jawaban/${params.id_paket_kuis_skor}/${params.id_kuis_soal}`,
            params,
            {
                headers: {
                    'Authorization': state.userLogin == null ? null : `Bearer ${state.userLogin.token}`
                }
            }
        );
        return res;
    },

    async postFinishQuiz({ commit, state }, params) {
        let res = await axios.post(
            `/kuis/selesai/${params.token}`,
            params,
            {
                headers: {
                    'Authorization': state.userLogin == null ? null : `Bearer ${state.userLogin.token}`
                }
            }
        );
        return res;
    },

    /**
     * Daftar Kuis di jawab user
     * @param {*} param0 
     * @param {*} params 
     */
    async getDaftarKuisDijawab({ commit, state }, params) {
        let res = await axios.get(
            `/kuis/${params.id_kategori}/hasil-kuis/list?page=${params.page}`,
            {
                headers: {
                    'Authorization': state.userLogin == null ? null : `Bearer ${state.userLogin.token}`
                }
            }
        );
        return res;
    },


    /**
     * Get Pembahasan kuis
     * @param {*} param0 
     * @param {*} params 
     */
    async getPembahasanPerKuis({ commit, state }, params) {
        let res = await axios.get(
            `/kuis/${params.id_kategori}/${params.id_kuis}/pembahasan-kuis?page=${params.page}`,
            {
                headers: {
                    'Authorization': state.userLogin == null ? null : `Bearer ${state.userLogin.token}`
                }
            }
        );
        return res;
    },


    /**
     * Ambil daftar percobaan kuis oleh user
     * @param {*} param0 
     * @param {*} params 
     */
    async getDaftarPercobaanKuisUser({ commit, state }, params) {
        let res = await axios.get(
            `/kuis/${params.id_kategori}/hasil-kuis/list/${params.id_kuis}`,
            {
                headers: {
                    'Authorization': state.userLogin == null ? null : `Bearer ${state.userLogin.token}`
                }
            }
        );
        return res;
    },


    async getHasilKuis({ commit, state }, params) {
        let res = await axios.get(
            `/kuis/${params.id_paket_kuis_skor}/hasil-pembahasan`,
            {
                headers: {
                    'Authorization': state.userLogin == null ? null : `Bearer ${state.userLogin.token}`
                }
            }
        );
        return res;
    },


    // materi controller

    /**
     * Get list materi 
     * @param {*} param0 
     * @param {*} params 
     */
    async getDaftarMateriPerSubKategori({ commit, state }, params) {
        let res = await axios.get(`/materi/${params.id_kategori}/${params.id_sub_kategori}/get-materi-list`,
            {
                headers: {
                    'Authorization': state.userLogin == null ? null : `Bearer ${state.userLogin.token}`
                }
            }
        );
        return res;
    },

    /**
     * Get detail materi 
     * @param {*} param0 
     * @param {*} id_materi 
     */
    async getKontenMateriPerMateri({ commit, state }, id_materi) {
        let res = await axios.get(`/materi/${id_materi}`,
            {
                headers: {
                    'Authorization': state.userLogin == null ? null : `Bearer ${state.userLogin.token}`
                }
            }
        );
        return res;
    },

    /**
     * Baca konten materi
     * @param {*} param0 
     * @param {*} params 
     */
    async getDetailKontenMateri({ commit, state }, params) {
        let url = `/materi/${params.id_materi}/${params.id_materi_konten}`;
        if (params.id_materi_konten_sumber != null) {
            url = `${url}/${params.id_materi_konten_sumber}`;
        }
        let res = await axios.get(url,
            {
                headers: {
                    'Authorization': state.userLogin == null ? null : `Bearer ${state.userLogin.token}`
                }
            }
        );
        return res;
    },


    /**
     * Mulai start kuis konten
     * @param {*} param0 
     * @param {*} params 
     */
    async startKuisMateri({ commit, state }, params) {
        let res = await axios.post(
            `/materi/${params.id_materi}/${params.id_materi_konten}/start-test`,
            params,
            {
                headers: {
                    'Authorization': state.userLogin == null ? null : `Bearer ${state.userLogin.token}`
                }
            }
        );
        return res;
    },


    async getDetailSkorMateribyToken({ commit, state }, params) {
        let res = await axios.get(`/materi/konten-materi/jawab-kuis/${params.token}`,
            {
                headers: {
                    'Authorization': state.userLogin == null ? null : `Bearer ${state.userLogin.token}`
                }
            }
        );
        return res;
    },


     /**
     * Get Pembahasan kuis materi
     * @param {*} param0 
     * @param {*} params 
     */
     async getPembahasanPerKuisMateri({ commit, state }, params) {
        let res = await axios.get(
            `/materi/konten-materi/hasil-kuis/${params.id_materi_user_skor}`,
            {
                headers: {
                    'Authorization': state.userLogin == null ? null : `Bearer ${state.userLogin.token}`
                }
            }
        );
        return res;
    },



    /**
     * Get daftar pertanyaan kuis materi
     * @param {*} param0 
     * @param {*} params 
     */
    async getPertanyaanKuisMateri({ commit, state }, params) {
        let res = await axios.get(`/materi/konten-materi/jawab-kuis/${params.token}/pertanyaan?page=${params.page}`,
            {
                headers: {
                    'Authorization': state.userLogin == null ? null : `Bearer ${state.userLogin.token}`
                }
            }
        );
        return res;
    },

    /**
     * Simpan jawaban kuis
     * @param {*} param0 
     * @param {*} params 
     */
    async postJawabanKuisMateri({ commit, state }, params) {
        let res = await axios.post(
            `/materi/konten-materi/jawab-kuis/${params.token}/pertanyaan`,
            params,
            {
                headers: {
                    'Authorization': state.userLogin == null ? null : `Bearer ${state.userLogin.token}`
                }
            }
        );
        return res;
    },

    /**
     * Selesai menjawab kuis
     * @param {*} param0 
     * @param {*} params 
     */
    async selesaiKuisMateri({ commit, state }, params) {
        let res = await axios.post(
            `/materi/konten-materi/finish-kuis/${params.token}`,
            params,
            {
                headers: {
                    'Authorization': state.userLogin == null ? null : `Bearer ${state.userLogin.token}`
                }
            }
        );
        return res;
    },


    async getPromo({ commit, state }, params) {
        let res = await axios.get(`/promo?page=${params.page}`);
        return res;
    },

    async getDetailPromo({ commit, state }, params) {
        let res = await axios.get(`/promo/${params.id_promo}`);
        return res;
    },

    async checkKodePromo({ commit, state }, params) {
        let res = await axios.post(
            `/promo/check`,
            params,
            {
                headers: {
                    'Authorization': state.userLogin == null ? null : `Bearer ${state.userLogin.token}`
                }
            }
        );
        return res;
    },

    async getRapor({ commit, state }, params) {
        let res = await axios.get(`/rapor/${params.singkatan_kategori}`,
            {
                headers: {
                    'Authorization': state.userLogin == null ? null : `Bearer ${state.userLogin.token}`,
                },
                responseType : 'blob'
            }
        );
        return res;
    },

    async getRaporUrl({ commit, state }, params) {
        let token = state.userLogin == null ? null : `${state.userLogin.token}`;
        let baseUrl = axios.defaults.baseURL;
        let url = `${baseUrl}/rapor/${params.singkatan_kategori}?token=${token}`;
        return url;
    },

    async getRaporPerKuis({ commit, state }, params) {
        let res = await axios.get(`/rapor/detail-per-kuis/${params.id_kuis}`,
            {
                headers: {
                    'Authorization': state.userLogin == null ? null : `Bearer ${state.userLogin.token}`,
                },
            }
        );
        return res;
    },


    async getPeringkatKuis({ commit, state }, params) {
        let res = await axios.get(`/rapor/peringkat-per-kuis/${params.id_kuis}`,
            {
                headers: {
                    'Authorization': state.userLogin == null ? null : `Bearer ${state.userLogin.token}`,
                },
            }
        );
        return res;
    },


    /**
     * Ambil daftar tagihan 
     * @param {*} param0 
     * @param {*} params 
     * @returns 
     */
    async getDaftarTagihanPaket({ commit, state }, params) {
        let res = await axios.get(`paket/user-paket/tagihan-pembayaran?page=${params.page}`, {
            headers: {
                'Authorization': state.userLogin == null ? null : `Bearer ${state.userLogin.token}`
            }
        });
        return res;
    },

    /**
     * Detail tagihan paket
     * @param {*} param0 
     * @param {*} params 
     * @returns 
     */
    async getDetailTagihanPaket({ commit, state }, params) {
        let res = await axios.get(`paket/user-paket/tagihan-pembayaran/${params.id_paket_user}`, {
            headers: {
                'Authorization': state.userLogin == null ? null : `Bearer ${state.userLogin.token}`
            }
        });
        return res;
    },

    
    async postReportSoal({ commit, state }, params) {
        let res = await axios.post(
            `/report/soal`,
            params,
            {
                headers: {
                    'Authorization': state.userLogin == null ? null : `Bearer ${state.userLogin.token}`
                }
            }
        );
        return res;
    },

    addTokenToUrl({ commit, state }, params) {
        let token = state.userLogin == null ? null : `${state.userLogin.token}`;
        let baseUrl = params;
        let url = `${baseUrl}?token=${token}`;
        return url;
    },

    getToken({ commit, state }) {
        let token = state.userLogin == null ? null : `${state.userLogin.token}`;
        return token;
    },

}

export default actions