import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import axios from 'axios'
import VueLazyload from 'vue-lazyload'
import VueClipboard from 'vue-clipboard2'
import VuePlyr from 'vue-plyr'

import routes from './routes'
import store from './store'
import './css/main.css'
import 'vue-plyr/dist/vue-plyr.css' 
import moment from 'moment'

Vue.config.productionTip = false
Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(VueLazyload)
Vue.use(VueClipboard)
Vue.use(VuePlyr, {
    plyr: {}
})

Number.prototype.pad = function (size) {
    var s = String(this);
    while (s.length < (size || 2)) { s = "0" + s; }
    return s;
};

Vue.filter('moneyFormat', (input) => {
    input = Number(input)
    if (isNaN(input)) {
      return 0
    }
    return input.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.')
})
  
Vue.filter('toHumanDate', (date) => {
    if (date === null || date === '' || typeof date === 'undefined' || date === 'undefined') {
      return ''
    }
    date = date.toString()
    const monthList = ['', 'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember']
    const dateArray = date.split('-')
  
    if (date.includes(' ')) {
      const aa = dateArray[2].split(' ')
      return `${aa[0]} ${monthList[Number(dateArray[1])]} ${dateArray[0]} ` + `pukul ${aa[1]}`
    } else {
      return `${dateArray[2]} ${monthList[dateArray[1]]} ${dateArray[0]}`
    }
})
  
Vue.filter('toHumanDate2', (date) => {
    return moment(date).format('lll');
})

Vue.filter('toHumanDate3', (date) => {
    return moment(date).format('LLL');
})
  
  
const router = new VueRouter({
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes
})

router.beforeEach((to, from, next) => {
    if (!to.meta.middleware) {
        return next()
    }
    const middleware = to.meta.middleware

    const context = {
        to,
        from,
        next,
        store
    }

    return middleware[0]({
        ...context
    })
})


// init user data
const userLogin = localStorage.getItem('userLogin')

if (userLogin != null) {
    let userLoginData = JSON.parse(userLogin)
    store.dispatch('actionLoginUser', userLoginData)
    try {
        if (userLoginData != null) {
            axios.get('/user/profil', {
                headers: {
                    'Authorization': `Bearer ${userLoginData.token}`
                }
            }).then((result) => {
                var res = result.data;

                if (res.status == 'success') {
                    store.dispatch('actionLoginUser', res.data)
                } else {
                    store.dispatch('actionLoginUser', null)
                }

            }).catch((error) => {
                store.dispatch('actionLoginUser', null)
            });
        }
    } catch (e) {   
        store.dispatch('actionLoginUser', null)
    }
}

// init konten
axios.get('/master/konten', {}).then((result) => {
    var res = result.data;
    if (res.status == 'success') {
        store.dispatch('actionChangeKonten', res.data)
    } else {
        store.dispatch('actionChangeKonten', null)
    }
}).catch((error) => {
    store.dispatch('actionChangeKonten', null)
});


window.redirectNotification = function (id_pemberitahuan) {
    router.push({
        name: 'PemberitahuanRedirecter',
        params: {
            id_pemberitahuan: id_pemberitahuan
        }
    });
}

import Default from './layouts/Default.vue'
import Empty from './layouts/Empty.vue'
import Clear from './layouts/Clear.vue'
Vue.component('default-layout', Default)
Vue.component('empty-layout', Empty)
Vue.component('clear-layout', Clear)

new Vue({ // eslint-disable-line no-new
  router,
  el: '#app',
  render: h => h(App),
  store
})
