<template>
    <div id="app">
        <component :is="layout">
            <router-view></router-view>
        </component>
    </div>
</template>

<script>
    export default {
        computed: {
            layout() {
                if(typeof this.$route.meta.layout != 'undefined'){
                    return this.$route.meta.layout + '-layout'
                } else {
					return 'default-layout';
				}
            }
        }
    }
</script>
