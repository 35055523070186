<template>
    <div class="adisparta-app relative">
        <div class="max-w-4xl mx-auto">
            <slot/>
        </div>
        <error-modal/>
        <toast-notif/>

    </div>
</template>

<script>
    import ErrorModal from '../components/ErrorModal.vue'
    import ToastNotif from '../components/ToastNotif.vue'
    export default {
        name: 'Empty',
        components : {
            ErrorModal, ToastNotif
        },
		computed: {
			userLogin () {
				return this.$store.getters.userLogin
            }
        },
    }
</script>

<style scoped>
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
</style>