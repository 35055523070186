<template>
    <div class="adisparta-app relative bg-gray-50 min-h-screen">
        
        <!-- top navbar on web -->
        <nav class="bg-white z-10 hidden sm:block fixed right-0 left-0">
            <div class="max-w-4xl mx-auto">
                <div class="relative flex items-center justify-between h-16">

                    <div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                        <div class="flex-shrink-0 flex items-center">
                            <img class="block lg:hidden h-8 w-auto" src="@/assets/images/logo.png" alt="">
                            <img class="hidden lg:block h-8 w-auto" src="@/assets/images/logo.png" alt="">
                            <span class="ml-1">E-learning Gamifikasi</span>
                        </div>
                    </div>

					<div v-if="userLogin == null">
						<router-link :to="{name : 'login'}" class="transition-all text-blue-700 hover:text-blue-800 bg-indigo-200 hover:bg-blue-300 px-3 py-2 rounded-md text-sm font-medium mr-1">Login</router-link>
						<!-- <router-link :to="{name : 'register'}" class="transition-all text-blue-100 hover:text-blue-200 bg-blue-600 hover:bg-blue-700 px-3 py-2 rounded-md text-sm font-medium">Register</router-link> -->
					</div>

                    <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0" v-if="userLogin != null ">
                        
                        <router-link to="/pemberitahuan" class="bg-gray-100 p-1 rounded-full text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-200 focus:ring-white">
                            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                            </svg>
                        </router-link>

                        <!-- Profile dropdown -->
                        <div class="ml-3 relative">
                            <div>
                                <router-link :to="{name : 'ProfilIndex'}" class="bg-gray-200 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-200 focus:ring-white" id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                                    <img class="h-8 w-8 rounded-full" :src="userLogin.user.avatar" alt="">
                                </router-link>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </nav>

        <div class="md:h-16"/>
        <div class="max-w-4xl mx-auto pt-0 px-0 md:pt-3">
            <slot/>
        </div>

        <div class="h-14 sm:hidden block"/>

         <!-- bottom navbar on mobile -->
        <nav class="block fixed inset-x-0 bottom-0 z-10 bg-white shadow md:hidden">
            <div class="flex justify-between">
                <router-link :to="{name : 'appHome'}" class="w-full focus:text-gray-800 hover:text-gray-800 justify-center inline-block text-center pt-2 pb-1">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                    </svg>
                    <span class="tab tab-home block text-xs">Home</span>
                </router-link>
                <!-- <router-link :to="{name : 'ExplorePaket', params : {'tipe_modul' : 'berbayar'}}" class="w-full focus:text-gray-800 hover:text-gray-800 justify-center inline-block text-center pt-2 pb-1">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
                    </svg>
                    <span class="tab tab-home block text-xs">Beli Paket</span>
                </router-link>
                <router-link :to="{name : 'ExplorePaket', params : {'tipe_modul' : 'event'}}" class="w-full focus:text-gray-800 hover:text-gray-800 justify-center inline-block text-center pt-2 pb-1">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                    <span class="tab tab-home block text-xs">Event</span>
                </router-link> -->
                <router-link :to="{name : 'login'}" v-if="userLogin == null" class="w-full focus:text-gray-800 hover:text-gray-800 justify-center inline-block text-center pt-2 pb-1">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
                    </svg>
                    <span class="tab tab-home block text-xs">Login</span>
                </router-link>

                <router-link :to="{name: 'ProfilIndex'}" v-if="userLogin != null" class="w-full focus:text-gray-800 hover:text-gray-800 justify-center inline-block text-center pt-2 pb-1">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                    </svg>
                    <span class="tab tab-home block text-xs">Profil</span>
                </router-link>
                
            </div>
        </nav>

        <error-modal/>
        <toast-notif/>

    </div>
</template>

<script>
    import ErrorModal from '../components/ErrorModal.vue'
    import ToastNotif from '../components/ToastNotif.vue'
    export default {
        name: 'Default',
        components : {
            ErrorModal, ToastNotif
        },
		computed: {
			userLogin () {
				return this.$store.getters.userLogin
            }
        },

        mounted : function() {
        },

        created() {
            // window.addEventListener('resize', this.handleResize);
            // this.handleResize();
        },
        destroyed() {
            // window.removeEventListener('resize', this.handleResize);
        },
        methods: {
            // handleResize() {
            //     const width = window.innerWidth;
            //     if(width < 600) {
            //         document.body.style.zoom = 1.1;
            //     } else {
            //         document.body.style.zoom = 1;
            //     }
            // }
        }
    }
</script>

<style scoped>
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
</style>